<template>
  <div
    ref="content"
    :style="{
      ...floatingStyles,
    }"
    @mouseenter="emits('mouseenter')"
    @mouseleave="emits('mouseleave')"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
import { autoUpdate, flip, useFloating, type ReferenceElement } from '@floating-ui/vue'

const props = defineProps<{
  reference: ReferenceElement
}>()

const emits = defineEmits(['mouseenter', 'mouseleave'])

const referenceRef = toRef(props, 'reference')

const content = ref(null)

const { floatingStyles } = useFloating(referenceRef, content, {
  middleware: [
    flip({
      fallbackPlacements: ['top', 'bottom', 'left', 'right'],
    }),
  ],
  placement: 'top',
  whileElementsMounted: autoUpdate,
})
</script>

<style scoped></style>
